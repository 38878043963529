import React from "react"
import { Link, graphql } from "gatsby"

import { PlusSmIcon } from '@heroicons/react/solid'

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import CommunitiesTable from "../components/community/communitiestable"
// import PlatformRedirect from "../components/platformredirect"
// import FeatureRedirect from "../components/featureredirect"

const CommunitiesPage = ({data, pageContext}) => {

  // top communities to show (if we are on the first page)
  const countTopToShow = 3;
  const topCommunities = data.topCommunities.nodes
    .filter(c => parseInt(c.reviewAvg) >= 4)
    .map(c => Object.assign(c, {'stars': c.reviewCount * c.reviewAvg}))
    .sort((a,b) => (a.stars < b.stars) ? 1 : ((b.stars < a.stars) ? -1 : 0))
    .slice(0, countTopToShow);

  // split up the communities by launch date
  var days = {}; // key is date (called updated but really it's added)
  data.communities.nodes.forEach(c => {
    
    // get date (convert from gsheets format -> 3/22/2023 gets converted to 2023-03-21T07:00:00.000Z)
    var date = ''
    if (c.updated){
      var d = new Date(c.updated);
      // d.setDate(d.getDate() + 1); // need to add a day locally (but not on prod WTF...)
      date = d.toISOString().split('T')[0]
    }
    
    // populate date dict
    if (!days[date]){
      days[date] = []
    }
    days[date].push(c)
  });

  return (
    <Layout pageTitle="New Communities" enableShare={true}>
      <SEO title="New Communities | Hive Index" description={`Discover the latest online community launches across platforms like Reddit, Slack, and Discord.`}
        dontIndex={pageContext.currentPage > 1}
      />

      {/*<div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            Directory of Online Communities
          </h2>
        </div>
      </div>*/}

      {/*<div className="mt-1 text-gray-500">
        The Hive Index organizes the internet's best communies by platform, topic, and feature.
        This page just lists all online communities, recently added first.
        To find what you're looking for quickly, please use the search bar on the top of the screen, or <Link to="/topics/" className="underline">view all topics</Link>.
      </div>*/}

      {pageContext.currentPage == 1 && topCommunities.length ? (
        <React.Fragment>
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex items-center justify-between">
              <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
                <div>Top-rated new communities</div>
              </h2>
            </div>
          </div>

          <div className="mt-2 mb-6">
            <CommunitiesTable showTopics={true} showPlatform={true} linkModal={true}
              communities={topCommunities} showVisitors={false} />
          </div>
        </React.Fragment>
      ) : ''}

      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            <div>{pageContext.currentPage == 1 ? 'Recent community launches' : 'All communities'}</div>
            {/*<span className="ml-2 font-semibold opacity-50 text-xs"> (page {pageContext.currentPage}/{pageContext.numPages})</span>*/}
          </h2>
          <Link to="/submit/">
            <button
              type="button"
              className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
            >
              <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>New</span>
            </button>
          </Link>
        </div>
      </div>

      <div className="">
        {Object.keys(days).sort((a,b) => (a < b) ? 1 : ((b < a) ? -1 : 0)).map(d => (
          <div className="mt-8" key={d}>
            {d ? (
              <h3 className="text-sm font-semibold text-gray-400 mb-1">Launched on {d}</h3>
            ) : ''}

            <CommunitiesTable communities={days[d]} showTopics={true} showPlatform={true} linkModal={true} />
          </div>
        ))}
       
      </div>

      <div className="p-4 mx-auto text-center">
        {pageContext.currentPage !== pageContext.numPages ? (
          <div className="mx-auto text-gray-600 font-semibold text-xs">
            <Link to={`/communities/page/${pageContext.currentPage + 1}/`} rel="noindex, nofollow" className="opacity-75 hover:opacity-100 cursor-pointer">Next Page</Link>
            <span> or </span>
            <Link to={'/topics/'} className="opacity-75 hover:opacity-100 cursor-pointer">Browse by Topic</Link>
          </div>
        ) : (
          <div className="mx-auto text-gray-600 font-semibold text-xs">
            Know an online community that is not on this list yet? <br/>
            Please <Link to="/submit/" className="opacity-75 hover:opacity-100 cursor-pointer">submit it</Link>!
          </div>
        )}
      </div>

    </Layout>
  )
}

export const query = graphql`
query communitiesListQuery($skip: Int!, $limit: Int!){
  communities: allGoogleSpreadsheetSourceCommunities(sort: {fields: order, order: DESC}, limit: $limit, skip: $skip, filter: {removed: {ne: true}}){
    totalCount
    nodes {
      name
      slug
      updated
      members
      established
      description
      topics
      platform
      logo
      forum
      chat
      apply
      pairing
      events
      perks
      jobs
      newsletter
      paid
      courses
      tools
      token
      reviewCount
      reviewAvg
      reviewsPositive
      visitors
      views
      redirects
    }
  }
  topCommunities: allGoogleSpreadsheetSourceCommunities(limit: 3, filter: {reviewCount: {nin: ["0"]}, reviewAvg: {in: ["4","5"]}, removed: {ne: true}}, sort: {fields: order, order: DESC},){
    totalCount
    nodes {
      name
      slug
      updated(formatString: "MM/DD/YY")
      members
      established
      description
      topics
      platform
      logo
      forum
      chat
      apply
      pairing
      events
      perks
      jobs
      newsletter
      paid
      courses
      tools
      token
      reviewCount
      reviewAvg
      reviewsPositive
      visitors
      views
      redirects
    }
  }
  allPlatforms: allGoogleSpreadsheetSourcePlatforms(sort: {fields: name, order: ASC}) {
    nodes {
      slug
      name
      count
    }
  }
  allFeatures: allGoogleSpreadsheetSourceFeatures(sort: {fields: name, order: ASC}) {
    nodes {
      slug
      name
      count
    }
  }
}
`

export default CommunitiesPage
